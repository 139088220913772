import { get, post, put } from 'uns-common-react';
import {
	AuthTicket,
	ContactInfo,
	Service,
	ServiceIdentifier,
	ServiceInfo,
	ServiceInfoVerification,
} from 'uns-common/contracts';

const baseUrl = `${process.env.REACT_APP_API_HOST ?? ''}/api`;
const srBaseUrl = `${baseUrl}/service-registration`;
const ticketBaseUrl = `${baseUrl}/ticket`;

export const serviceRegistration = {
	/**
	 * Submits contact information to the Gatekeeper
	 * @param contactInfo all the contact information of the third party
	 */
	submitContactInfo: function (contactInfo: ContactInfo): Promise<void> {
		return post(`${srBaseUrl}/contactInfo`, contactInfo);
	},

	/**
	 * Method for email verification
	 * @param serviceId ID of the service
	 * @param emailVerificationCode verification code it got in the email
	 */
	verifyEmail: function (
		serviceId: string,
		emailVerificationCode: string
	): Promise<void> {
		return put(
			`${srBaseUrl}/verifyEmail/${serviceId}/${emailVerificationCode}`
		);
	},

	/**
	 * Submits service information to the Gatekeeper
	 * @param serviceId ID of the service
	 * @param serviceInfo Service information
	 */
	submitServiceInfo: function (
		serviceId: string,
		serviceInfo: ServiceInfo
	): Promise<
		Partial<ServiceIdentifier & ServiceInfo & ServiceInfoVerification>
	> {
		return post(`${srBaseUrl}/serviceInfo/${serviceId}`, serviceInfo);
	},

	/**
	 * Verifies the service by checking the third party service validation path
	 * @param serviceId ID of the service
	 */
	verifyDomain: function (serviceId: string): Promise<void> {
		return put(`${srBaseUrl}/verifyDomain/${serviceId}`);
	},

	/**
	 * Saves the public key for the third party service
	 * @param serviceId ID of the service
	 * @param publicKey Generated public key
	 */
	submitPublicKey: function (
		serviceId: string,
		publicKey: string
	): Promise<Service> {
		return post(`${srBaseUrl}/publicKey/${serviceId}`, publicKey);
	},
};

export const ticket = {
	/**
	 * Gets the Guardian, Gatekeeper and QR code URL
	 * @param nonce Nonce of the ticket
	 */
	getUrls: function (
		nonce: string
	): Promise<
		Pick<AuthTicket, 'guardianUrl' | 'qrCodeUrl'> & Pick<Service, 'domain'>
	> {
		return get(`${ticketBaseUrl}/urls/${nonce}`);
	},
};
