import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { ActionButton, useAsync } from 'uns-common-react';
import { ServiceIdentifier } from 'uns-common/contracts';
import { serviceRegistration } from '../../common/api';

export const ServiceInfoForm: FC = () => {
	const { serviceId } =
		useParams<ServiceIdentifier>() as Required<ServiceIdentifier>;
	// TODO: Fetch service info here and verify there's a service with the provided service id

	const [domain, setDomain] = useState('');
	const [callbackPath, setCallbackPath] = useState('');
	const [validationPath, setValidationPath] = useState('');
	const [validated, setValidated] = useState<boolean>();

	const {
		loading,
		error,
		data,
		execute: submitServiceInfo,
	} = useAsync(serviceRegistration.submitServiceInfo);

	const onSubmitClick = useCallback(() => setValidated(true), []);
	const onFormSubmit = useCallback(
		async (event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			if (event.currentTarget.checkValidity() === true)
				await submitServiceInfo(serviceId, {
					domain,
					callbackPath,
					validationPath,
				});

			return false;
		},
		[submitServiceInfo, serviceId, domain, callbackPath, validationPath]
	);

	// reset validated flag on input change
	useEffect(() => setValidated(false), [domain, callbackPath, validationPath]);

	return (
		<Form validated={validated} onSubmit={onFormSubmit}>
			<h4>Continue Registering Your Service</h4>
			<hr />

			{!data && (
				<>
					<div className="mb-5">
						<h5>Step 2: Provide Service Details</h5>
						<hr />
						<Form.Group className="mb-3">
							<Form.Label>Enter Your Domain Name:</Form.Label>
							<Form.Control
								value={domain}
								placeholder="https://example.com"
								type="url"
								name="domain"
								required
								onChange={({ target: { value } }) => setDomain(value)}
							/>
							<Form.Text className="text-muted">
								This should be your top level domain or the subdomain hosting
								your service application.
							</Form.Text>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Enter Your Callback Path:</Form.Label>
							<Form.Control
								value={callbackPath}
								placeholder="/auth"
								type="text"
								name="callbackPath"
								required
								onChange={({ target: { value } }) => setCallbackPath(value)}
							/>
							<Form.Text className="text-muted">
								This will be the path that UNS will post authentication tickets
								to during the authentication process.
							</Form.Text>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Enter Your Validation Path:</Form.Label>
							<Form.Control
								value={validationPath}
								placeholder="/validation"
								type="text"
								name="validationPath"
								required
								onChange={({ target: { value } }) => setValidationPath(value)}
							/>
							<Form.Text className="text-muted">
								Once you&apos;ve submitted this form we will send you a unique
								ID that you have to place in a path that UNS can access so that
								we can verify that you own the service at this domain.
							</Form.Text>
						</Form.Group>
					</div>

					<ActionButton
						type="submit"
						loading={loading}
						error={error?.message}
						onClick={onSubmitClick}
					>
						Submit Service Info
					</ActionButton>
				</>
			)}

			{data && (
				<Alert variant="success">
					<p>Your service information was submitted.</p>
					<p>
						Next, follow the instructions you see here which have also been
						emailed to you at the email address you provided.
					</p>
					<p>
						Please confirm ownership of the{' '}
						<Alert.Link as={'a'} href={data.domain} target="_blank">
							{data.domain}
						</Alert.Link>{' '}
						by returning the following code as plain text in your validation
						path (
						<Alert.Link
							as={'a'}
							href={`${data.domain}/${data.validationPath}`}
							target="_blank"
						>
							{data.domain}/{data.validationPath}
						</Alert.Link>
						):
					</p>
					<p>
						<strong>{data.validationCode}</strong>
					</p>
					Once complete please continue to the next stage of registration{' '}
					<Alert.Link as={Link} to={`/step3/${serviceId}`}>
						here
					</Alert.Link>
					.
				</Alert>
			)}
		</Form>
	);
};
