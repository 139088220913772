import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorPage, ServiceRegistrationPage, TicketPage } from './components';

export const App: FC = () => (
	<BrowserRouter>
		<Routes>
			<Route path="/ticket/:nonce" element={<TicketPage />} />
			<Route path="/error" element={<ErrorPage />} />
			<Route path="/*" element={<ServiceRegistrationPage />} />
		</Routes>
	</BrowserRouter>
);
