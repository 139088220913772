import { FC } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { AppHeader } from '../appHeader';
import { Page } from '../page';
import { Progress } from '../progress';
import { ContactInfoForm } from './contactInfoForm';
import { PublicKey } from './publicKey';
import { ServiceInfoForm } from './serviceInfoForm';
import { VerifyDomainForm } from './verifyDomainForm';
import { VerifyEmail } from './verifyEmail';

export const ServiceRegistrationPage: FC = () => {
	return (
		<Page header={<AppHeader />}>
			<Routes>
				<Route
					path="/verifyEmail/:serviceId/:emailVerificationCode"
					element={<VerifyEmail />}
				/>
				<Route path="*" element={<SRSteps />} />
			</Routes>
		</Page>
	);
};

const SRSteps: FC = () => {
	const firstStep = useMatch('/');
	const otherStep = useMatch('/:step/:rest');

	let step = firstStep ? 1 : 0;
	if (otherStep && otherStep.params.step && otherStep.params.rest) {
		const stepParam = otherStep.params.step;
		step = +stepParam[stepParam.length - 1];

		if (step > 4) step = 0; // Allow only steps 1 to 4
	}

	if (!step) return <Navigate to="/error" />;

	return (
		<>
			<Progress step={step} />
			<Routes>
				<Route index element={<ContactInfoForm />} />
				<Route path="/step2/:serviceId" element={<ServiceInfoForm />} />
				<Route path="/step3/:serviceId" element={<VerifyDomainForm />} />
				<Route path="/step4/:serviceId" element={<PublicKey />} />
			</Routes>
		</>
	);
};
