import { FC } from 'react';

export const IosAuthenticatorDownloadLink: FC = () => {
	return (
		<div className="d-flex justify-content-end align-items-center">
			<a
				target="_blank"
				rel="noreferrer"
				href="https://apps.apple.com/us/app/uns-authenticator/id1553453897"
			>
				Download the UNS Authenticator
				<img
					className="ms-3"
					alt="logo"
					src={process.env.PUBLIC_URL + '/img/logo.png'}
					width="50"
					height="50"
				/>
			</a>
		</div>
	);
};
