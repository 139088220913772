/**
 * Constructs and calls a GET request to the specified URL with headers
 * @template TResult What we expect to return
 * @param url URL where to fetch
 * @param headers Headers of the fetch API
 * @returns Result of the fetch request
 */
export async function get<TResult = void>(
	url: string,
	headers?: HeadersInit
): Promise<TResult> {
	const response = await fetch(url, { headers });
	return processResponse(response);
}

export async function httpDelete<TResult = void>(
	url: string,
	headers?: HeadersInit
): Promise<TResult> {
	const response = await fetch(url, {
		method: 'DELETE',
		headers: { ...headers },
	});
	return processResponse(response);
}

/**
 * Constructs and calls a POST request to the specified URL with body and headers
 * @template TBody Type of the body
 * @template TResult Type of the result
 * @param url URL where to fetch
 * @param body Body of the POST request
 * @param headers Header of the POST request
 * @returns Result of the fetch request
 */
export const post = <TBody, TResult = void>(
	url: string,
	body?: TBody,
	headers?: HeadersInit
) => postOrPut<TBody, TResult>('POST', url, body, headers);

/**
 * Constructs and calls a PUT request to the specified URL with body and headers
 * @template TBody Type of the body
 * @template TResult Type of the result
 * @param url URL where to fetch
 * @param body Body of the PUT request
 * @param headers Header of the PUT request
 * @returns Result of the fetch request
 */
export const put = <TBody, TResult = void>(
	url: string,
	body?: TBody,
	headers?: HeadersInit
) => postOrPut<TBody, TResult>('PUT', url, body, headers);

/**
 * Constructs and calls a PUT request to the specified URL with body and headers
 * @template TBody Type of the body
 * @template TResult Type of the result
 * @param method What request method to call (POST OR PUT)
 * @param url URL of the fetch request
 * @param body Body of the POST or PUT request
 * @param headers Headers of the POST or PUT request
 * @returns Reuslt of the fetch request
 */
async function postOrPut<TBody, TResult>(
	method: 'POST' | 'PUT',
	url: string,
	body?: TBody,
	headers?: HeadersInit
): Promise<TResult> {
	const isText = typeof body === 'string';
	const response = await fetch(url, {
		method,
		body: body ? (isText ? body : JSON.stringify(body)) : undefined,
		headers: {
			...headers,
			'content-type': isText ? 'text/plain' : 'application/json',
		},
	});

	return processResponse(response);
}

/**
 * Processes the response of the fetch request
 * @param response Response of the fetch request
 * @returns The result of the fetch request
 */
async function processResponse<TResult>(response: Response): Promise<TResult> {
	if (response.ok && response.status === 204)
		return undefined as unknown as TResult;

	const result =
		response.headers.get('content-type') === 'text/plain'
			? await response.text()
			: response.headers.get('content-type') === 'application/json'
			? await response.json()
			: URL.createObjectURL(await response.blob());

	if (response.ok) return result as TResult;
	else throw new Error(typeof result === 'string' ? result : result.message);
}
