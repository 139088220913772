import { FC } from 'react';
import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useAsync } from 'uns-common-react';
import { AuthTicketIdenfifier } from 'uns-common/contracts';
import { ticket } from '../../common/api';

export const Ticket: FC = () => {
	const { nonce } =
		useParams<AuthTicketIdenfifier>() as Required<AuthTicketIdenfifier>;

	const { loading, error, data } = useAsync(
		() => ticket.getUrls(nonce),
		[nonce],
		true
	);

	if (loading) return <Alert variant="light">Loading...</Alert>;
	if (error) return <Alert variant="danger">{error.message}</Alert>;
	if (!data) return <Alert variant="danger">No Data</Alert>;

	return (
		<div className="text-center">
			<div className="h5">Sign in to</div>
			<div>{data.domain}</div>
			<a href={data.guardianUrl} rel="noreferrer">
				<img src={data.qrCodeUrl} alt="qrCode" />
			</a>
			<div>
				or &nbsp;
				<a href={data.guardianUrl} rel="noreferrer">
					sign in using browser
				</a>
			</div>
		</div>
	);
};
