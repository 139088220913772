import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { Alert, Card, Form } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { ActionButton, useAsync } from 'uns-common-react';
import { ServiceIdentifier } from 'uns-common/contracts';
import { serviceRegistration } from '../../common/api';

export const PublicKey: FC = () => {
	const { serviceId } =
		useParams<ServiceIdentifier>() as Required<ServiceIdentifier>;
	// TODO: Fetch service info here and verify there's a service with the provided service id

	const [publicKey, setPublicKey] = useState('');
	const [validated, setValidated] = useState<boolean>();

	const {
		loading,
		error,
		success,
		execute: submitPublicKey,
	} = useAsync(serviceRegistration.submitPublicKey);

	const onSubmitClick = useCallback(() => setValidated(true), []);
	const onFormSubmit = useCallback(
		async (event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			if (event.currentTarget.checkValidity() === true)
				await submitPublicKey(serviceId, publicKey);

			return false;
		},
		[submitPublicKey, serviceId, publicKey]
	);

	// reset validated flag on input change
	useEffect(() => setValidated(false), [publicKey]);

	const location = useLocation();
	const showDomainVerificationSuccess = location.state
		? (location.state as { showDomainVerificationSuccess: boolean })
				.showDomainVerificationSuccess
		: false;

	return (
		<Form validated={validated} onSubmit={onFormSubmit}>
			<h4>Finish Registering Your Service</h4>
			<hr />

			{showDomainVerificationSuccess && loading === undefined && (
				<Alert variant="success" className="mb-3">
					Domain successfully verified.
				</Alert>
			)}

			{!success && (
				<>
					<div className="mb-5">
						<h5>Step 4: Public Key</h5>
						<p>
							UNS requires authentication requests to be signed by a private key
							that you own. In oder to validate the authenticity of these
							requests you will need to provide your gateway with the
							corresponding Public Key.
						</p>
						<p> To generate a key pair run the following on your terminal:</p>
						<p>
							<Card>
								<Card.Body>
									<div>openssl genrsa -out privatekey.pem 4096</div>
									<div>
										openssl rsa -in privatekey.pem -pubout -out publickey.pem
									</div>
								</Card.Body>
							</Card>
						</p>
						<p>
							<strong>Please be sure to save your private key.</strong>
						</p>
						<hr />
						<Form.Group className="mb-3">
							<Form.Label>Public Key:</Form.Label>
							<Form.Control
								value={publicKey}
								placeholder="Paste Public Key here..."
								name="publicKey"
								required
								onChange={(e) => setPublicKey(e.target.value)}
								as="textarea"
								rows={10}
							/>
						</Form.Group>
					</div>

					<ActionButton
						type="submit"
						loading={loading}
						error={error?.message}
						onClick={onSubmitClick}
					>
						Submit Public Key
					</ActionButton>
				</>
			)}

			{success && (
				<Alert variant="success">
					<div>
						Your Public Key was submitted. And your registration is now
						complete.
					</div>
					<div>Please check your email for implementation details.</div>
				</Alert>
			)}
		</Form>
	);
};
