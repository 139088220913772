import { FC, useCallback } from 'react';
import { Alert } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useAsync } from 'uns-common-react';
import {
	ContactInfoVerification,
	ServiceIdentifier,
} from 'uns-common/contracts';
import { serviceRegistration } from '../../common/api';

type VerifyEmailParams = ServiceIdentifier &
	Pick<ContactInfoVerification, 'emailVerificationCode'>;

export const VerifyEmail: FC = () => {
	const { serviceId, emailVerificationCode } =
		useParams<VerifyEmailParams>() as Required<VerifyEmailParams>;
	// TODO: Fetch service info here and verify there's a service with the provided service id

	const verifyEmail = useCallback(
		() => serviceRegistration.verifyEmail(serviceId, emailVerificationCode),
		[serviceId, emailVerificationCode]
	);
	const { loading, error } = useAsync(verifyEmail, [], true);

	if (loading) return <Alert variant="light">Loading...</Alert>;
	if (error) return <Alert variant="danger">{error.message}</Alert>;

	return (
		<Alert variant="success">
			<div>Your email has been verified.</div>
			<div>
				Please continue the registration process{' '}
				<Alert.Link as={Link} to={`/step2/${serviceId}`}>
					here
				</Alert.Link>
				.
			</div>
		</Alert>
	);
};
