import { Environment } from '../contracts';
import { BaseLogger } from './base-logger';
import { ConsoleLogger } from './console-logger';
import { LogglyLogger } from './loggly-logger';

export let logger: BaseLogger;

export function initLogger(environment: Environment, tag: string): BaseLogger {
	return (logger =
		environment === Environment.development
			? new ConsoleLogger(tag)
			: new LogglyLogger(
					environment,
					tag,
					'024bde2a-4ec8-49ae-bf67-403968fb5e4f'
			  )); // TODO: Move token to env vars
}
