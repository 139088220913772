import { FC, ReactNode } from 'react';
import './progress.scss';

type ProgressProps = { step: number };

export const Progress: FC<ProgressProps> = ({ step }) => {
	const steps = 4;
	const circleStyle = { width: '2.5rem', height: '2.5rem' };

	const singleCircle = (num: number) => {
		let className =
			'd-flex justify-content-center align-items-center border border-dark rounded-circle';
		if (num < step) className += ' bg-light-success';
		else if (num === step) className += ' bg-light fw-bold';
		return (
			<div key={(num - 1) * 2} className={className} style={circleStyle}>
				{num}
			</div>
		);
	};

	const circles: ReactNode[] = [];
	for (let i = 1; i <= steps; i++) {
		circles.push(singleCircle(i));
		if (i < steps)
			circles.push(<div className="divider" key={(i - 1) * 2 + 1} />);
	}

	return (
		<div className="d-flex justify-content-center">
			<div className="d-flex mb-3">{circles}</div>
		</div>
	);
};
