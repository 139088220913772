import { FC } from 'react';
import { IosAuthenticatorDownloadLink } from '../iosAuthenticatorDownloadLink';
import { Page } from '../page';
import { Ticket } from './ticket';

export const TicketPage: FC = () => {
	return (
		<Page>
			<Ticket />
			<hr />
			<IosAuthenticatorDownloadLink />
		</Page>
	);
};
