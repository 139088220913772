import ReactDOM from 'react-dom/client';
import { ENVIRONMENT, IS_DEVELOPMENT } from 'uns-common-react';
import { initLogger } from 'uns-common/logger';
import { App } from './app';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const logger = initLogger(ENVIRONMENT, 'gatekeeper-ui');

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(IS_DEVELOPMENT ? (x) => logger.debug(x) : undefined);
