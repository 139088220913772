import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { ActionButton, useAsync } from 'uns-common-react';
import { serviceRegistration } from '../../common/api';

export const ContactInfoForm: FC = () => {
	const [contactName, setContactName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [validated, setValidated] = useState<boolean>();

	const {
		loading,
		error,
		success,
		execute: submitContactInfo,
	} = useAsync(serviceRegistration.submitContactInfo);

	const onSubmitClick = useCallback(() => setValidated(true), []);
	const onFormSubmit = useCallback(
		async (event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			if (event.currentTarget.checkValidity() === true)
				await submitContactInfo({ contactName, email, phoneNumber });

			return false;
		},
		[submitContactInfo, contactName, email, phoneNumber]
	);

	// reset validated flag on input change
	useEffect(() => setValidated(false), [contactName, email, phoneNumber]);

	return (
		<Form validated={validated} onSubmit={onFormSubmit}>
			<h4>Register Your Service with UNS</h4>
			<p>
				Please complete this form to register your service with UNS. If you
				would like more information on the registration process and how to
				implement UNS please visit: link
			</p>
			<hr />

			{!success && (
				<>
					<div className="mb-5">
						<h5>Step 1: Identify a Point of Contact</h5>
						<p>Tell us who will act as your technical point of contact.</p>
						<hr />
						<Form.Group className="mb-3">
							<Form.Label>Contact name:</Form.Label>
							<Form.Control
								value={contactName}
								placeholder="John Doe"
								type="text"
								name="contactName"
								required
								onChange={({ target: { value } }) => setContactName(value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Email:</Form.Label>

							<Form.Control
								value={email}
								placeholder="me@example.com"
								type="email"
								name="email"
								required
								onChange={({ target: { value } }) => setEmail(value)}
							/>
							<Form.Text>
								Please note that you will need to verify this email address and
								that correspondence necessary to complete this registration
								process will be sent to that email address.
							</Form.Text>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Phone number:</Form.Label>
							<Form.Control
								value={phoneNumber}
								placeholder="0000000000"
								pattern="[0-9]+"
								name="phoneNumber"
								required
								onChange={({ target: { value } }) => setPhoneNumber(value)}
							/>
						</Form.Group>
					</div>

					<ActionButton
						type="submit"
						loading={loading}
						error={error?.message}
						onClick={onSubmitClick}
					>
						Submit Contact Info
					</ActionButton>
				</>
			)}

			{success && (
				<Alert variant="success">
					Success! Your contact information was submitted. Please check your
					email and follow the instructions for verifying your email address.
				</Alert>
			)}
		</Form>
	);
};
