import { FC } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const AppHeader: FC = () => {
	return (
		<Navbar
			bg="white"
			className="border border-primary border-top-0 rounded-bottom p-3"
		>
			<Navbar.Brand>
				<Nav.Link
					as={Link}
					to={process.env.PUBLIC_URL + '/'}
					className="d-flex align-items-center text-primary"
				>
					<img
						alt="logo"
						src={process.env.PUBLIC_URL + '/img/logo-outlined.png'}
						width="175"
						className="me-2"
					/>
					<strong className="ms-1">UNS</strong>&nbsp;Gatekeeper
				</Nav.Link>
			</Navbar.Brand>
		</Navbar>
	);
};
