import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ActionButton, useAsync } from 'uns-common-react';
import { ServiceIdentifier } from 'uns-common/contracts';
import { serviceRegistration } from '../../common/api';

export const VerifyDomainForm: FC = () => {
	const { serviceId } =
		useParams<ServiceIdentifier>() as Required<ServiceIdentifier>;
	// TODO: Fetch service info here and verify there's a service with the provided service id

	const [domain, setDomain] = useState('');
	const [validated, setValidated] = useState<boolean>();

	const {
		loading,
		error,
		success,
		execute: verifyDomain,
	} = useAsync(serviceRegistration.verifyDomain);

	const onSubmitClick = useCallback(() => setValidated(true), []);
	const onFormSubmit = useCallback(
		async (event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			if (event.currentTarget.checkValidity() === true)
				await verifyDomain(serviceId);

			return false;
		},
		[verifyDomain, serviceId]
	);

	// reset validated flag on input change
	useEffect(() => setValidated(false), [domain]);

	const navigate = useNavigate();
	useEffect(() => {
		if (success)
			navigate(`/step4/${serviceId}`, {
				state: { showDomainVerificationSuccess: true },
			});
	}, [success, navigate, serviceId]);

	return (
		<Form validated={validated} onSubmit={onFormSubmit}>
			<h4>Continue Registering Your Service</h4>
			<hr />
			{!success && (
				<>
					<div className="mb-5">
						<h5>Step 3: Confirm Service Ownership</h5>
						<p>
							We would like to confirm that the unique ID is reachable at the
							path you provided.
						</p>
						<hr />
						<Form.Group className="mb-3">
							<Form.Label>Enter Your Domain Name:</Form.Label>
							<Form.Control
								value={domain}
								placeholder="https://example.com"
								type="url"
								name="domain"
								required
								onChange={({ target: { value } }) => setDomain(value)}
							/>
							<Form.Text className="text-muted">
								This should be your top level domain or the subdomain hosting
								your service application.
							</Form.Text>
						</Form.Group>
					</div>

					<ActionButton
						type="submit"
						loading={loading}
						error={error?.message}
						onClick={onSubmitClick}
					>
						Confirm Service Ownership
					</ActionButton>
				</>
			)}

			{success && (
				<Alert variant="success">
					<div>Your domain has been verified.</div>
					<div>
						Please continue the registration process{' '}
						<Alert.Link as={Link} to={`/step4/${serviceId}`}>
							here
						</Alert.Link>
						.
					</div>
				</Alert>
			)}
		</Form>
	);
};
